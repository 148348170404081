import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute";
import * as routes from "../../constants/navigationConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import UserAppConfigController from "../../controllers/UserAppConfigController/UserAppConfigController";

import CompaniesRoute from "../CompaniesRoute/CompaniesRoute";
import CompanyDetailedViewRoute from "../CompanyDetailedViewRoute/CompanyDetailedViewRoute";
import DashboardRoute from "../DashboardRoute/DashboardRoute";
import HomeRoute from "../HomeRoute/HomeRoute";
import InventoryRoute from "../InventoryRoute/InventoryRoute";
import InvoicesRoute from "../InvoicesRoute/InvoicesRoute";
import LoginRoute from "../LoginRoute/LoginRoute";
import OrdersRoute from "../OrdersRoute/OrdersRoute";
import TicketsRoute from "../TicketsRoute/TicketsRoute";
import UsersRoute from "../UsersRoute/UsersRoute";

const RouteProvider = (): React.ReactElement => (
  <Routes>
    <Route path={routes.LOGIN_ROUTE} element={<LoginRoute />} />

    <Route element={<ProtectedRoute />}>
      <Route path="*" element={<AllRoutesWithUserAppConfig />} />
    </Route>
  </Routes>
);

const AllRoutesWithUserAppConfig = () => (
  <UserAppConfigController>
    <AllRoutes />
  </UserAppConfigController>
);

const AllRoutes = () => {
  const { isAdmin } = useUserAppConfigStore();

  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />

      {isAdmin ? (
        <>
          <Route path={routes.INVENTORY_ROUTE} element={<InventoryRoute />} />

          <Route path={routes.COMPANIES_ROUTE} element={<CompaniesRoute />} />

          <Route path={routes.USERS_ROUTE} element={<UsersRoute />} />
        </>
      ) : null}

      <Route path={routes.DASHBOARD_ROUTE} element={<DashboardRoute />} />

      <Route
        path={routes.COMPANY_DETAILED_VIEW_ROUTE}
        element={<CompanyDetailedViewRoute />}
      />

      <Route path={routes.TICKETS_ROUTE} element={<TicketsRoute />} />

      <Route path={routes.ORDERS_ROUTE} element={<OrdersRoute />} />

      <Route path={routes.INVOICES_ROUTE} element={<InvoicesRoute />} />
    </Routes>
  );
};

export default RouteProvider;
