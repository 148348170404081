import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPreSignedGetUrlForInvoiceQueryVariables = Types.Exact<{
  params: Types.GetPreSignedGetUrlForInvoiceParams;
}>;


export type GetPreSignedGetUrlForInvoiceQuery = { __typename?: 'Query', getPreSignedGetUrlForInvoice: { __typename?: 'GetPreSignedGetUrlForInvoiceResult', signedUrl: string } | { __typename?: 'InvoiceAccessDenied' } | { __typename?: 'InvoiceNotFound' } };


export const GetPreSignedGetUrlForInvoiceDocument = gql`
    query GetPreSignedGetUrlForInvoice($params: GetPreSignedGetUrlForInvoiceParams!) {
  getPreSignedGetUrlForInvoice(params: $params) {
    ... on GetPreSignedGetUrlForInvoiceResult {
      signedUrl
    }
  }
}
    `;
export type GetPreSignedGetUrlForInvoiceQueryResult = Apollo.QueryResult<GetPreSignedGetUrlForInvoiceQuery, GetPreSignedGetUrlForInvoiceQueryVariables>;