import { Content, Heading, IllustratedMessage } from "@adobe/react-spectrum";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import type { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { useGetOrdersInAccount } from "../../apis/GetOrdersInAccount/useGetOrdersInAccount";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { Order } from "../../graphql/graphqlTypes";

import AcknowledgeOrderModal from "../AcknowledgeOrderModal/AcknowledgeOrderModal";
import Loader from "../Loader/Loader";
import UpdateOrderModal from "../UpdateOrderModal/UpdateOrderModal";

const renderStatusBadge = (
  isAcknowledged?: boolean,
  isDeleted?: boolean
): React.ReactElement => {
  switch (true) {
    case isAcknowledged:
      return (
        <div className="bg-green-100 px-2 py-1 rounded-2xl font-medium text-green-600 text-sm">
          Delivered
        </div>
      );
    case isDeleted:
      return (
        <div className="bg-red-100 px-2 py-1 rounded-2xl font-medium text-red-600 text-sm">
          Cancelled
        </div>
      );
    default:
      return (
        <div className="bg-blue-100 px-2 py-1 rounded-2xl font-medium text-blue-600 text-sm">
          Pending
        </div>
      );
  }
};

interface Props {
  updateRefreshId: () => void;
}

const OrdersTable = (props: Props): React.ReactElement => {
  const gridRef = useRef<AgGridReact<Order>>(null);

  const { isAdmin, companyId } = useUserAppConfigStore();

  const [activeOrder, setActiveOrder] = useState<Order | null>(null);
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);

  const onClose = () => setActiveOrder(null);

  const getOrdersAPI = useGetOrdersInAccount();

  const getOrders = (offset = 0) => {
    return getOrdersAPI.triggerAPI({
      reqObj: {
        companyId,
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
      },
    });
  };

  const datasource: IDatasource = useMemo(
    () => ({
      getRows: async (params: IGetRowsParams) => {
        const { startRow, endRow, successCallback, failCallback } = params;

        try {
          const response = await getOrders(startRow);

          const data: Order[] = response;

          const lastRow =
            data.length < endRow - startRow
              ? startRow + data.length
              : undefined;

          if (startRow === 0) setShowNoSearchResults(data.length === 0);

          successCallback(data, lastRow);
        } catch (error) {
          failCallback();
        }
      },
    }),
    []
  );

  const renderNoDataView = (): React.ReactElement => (
    <div className="bg-gray-50 w-full h-full">
      <IllustratedMessage>
        <NoSearchResults />
        <Heading>No Orders Found</Heading>
        <Content>No Orders are raised yet</Content>
      </IllustratedMessage>
    </div>
  );

  const columnDefs: ColDef[] = [
    {
      field: "companyName",
      headerName: "Company",
      width: 200,
      hide: !isAdmin,
    },

    {
      field: "entity",
      headerName: "Laptop Configuration",
      width: 300,
    },

    {
      field: "quantity",
      headerName: "Quantity",
      width: 120,
    },

    {
      field: "orderId",
      headerName: "Status",
      width: 150,

      cellRenderer: (value: any) => {
        const order = value.data as Order;
        return (
          <div className="flex items-center h-full">
            {renderStatusBadge(order?.isAcknowledged, order?.isDeleted)}
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Remarks",
      width: 150,
    },
    {
      field: "expectedDeliveryDate",
      headerName: "Expected Delivery Date",
      width: 200,
    },
    {
      field: "acknowledgedBy",
      headerName: "Order Acknowledged By",
      width: 200,
      valueFormatter: (value) => value.value?.name ?? "-",
      hide: !isAdmin,
    },
  ];

  return (
    <div className="flex-1 mt-2 w-full h-full ag-theme-quartz">
      {showNoSearchResults ? (
        renderNoDataView()
      ) : (
        <AgGridReact<Order>
          ref={gridRef}
          columnDefs={columnDefs as any}
          defaultColDef={{
            sortable: false,
            onCellClicked({ data }) {
              if (data && !data.isAcknowledged && !data.isDeleted) {
                setActiveOrder(data);
              }
            },
          }}
          rowModelType="infinite"
          cacheBlockSize={DEFAULT_API_LIMIT}
          maxConcurrentDatasourceRequests={1}
          datasource={datasource}
          loading={getOrdersAPI.isLoading}
          loadingOverlayComponent={() => <Loader />}
        />
      )}

      {!isAdmin && activeOrder ? (
        <UpdateOrderModal
          order={activeOrder}
          isOpen={!!activeOrder}
          onClose={onClose}
          updateRefreshId={props.updateRefreshId}
        />
      ) : null}

      {isAdmin && activeOrder ? (
        <AcknowledgeOrderModal
          order={activeOrder}
          isOpen={!!activeOrder}
          onClose={onClose}
          updateRefreshId={props.updateRefreshId}
        />
      ) : null}
    </div>
  );
};

export default OrdersTable;
