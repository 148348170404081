import { Order } from "../../graphql/graphqlTypes";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetOrdersInAccountQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => Order[];
}

export const useResponseHandler = (): ReturnType => {
  const handleResponse = (args: ArgsType): Order[] => {
    const response = args.data.getOrdersInAccount;

    switch (response.__typename) {
      case "GetOrdersInAccountResult": {
        args.onSuccess?.();
        const orders: Order[] = [];
        response.orders?.forEach((item) => {
          if (item) orders.push(item as Order);
        });

        return orders;
      }
      case "CompanyNotFound":
      case "InvalidLimit":
      case "InvalidOffset":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
    return [];
  };

  return {
    handleResponse,
  };
};
