import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import OrdersTable from "../../components/OrdersTable/OrdersTable";
import PlaceOrderModal from "../../components/PlaceOrderModal/PlaceOrderModal";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";

const OrdersController = (): React.ReactElement => {
  const [refreshId, setRefreshId] = useState("");

  const updateRefreshId = (): void => setRefreshId(Date.now().toString());

  const { isAdmin } = useUserAppConfigStore();

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>Orders</p>
      {isAdmin ? null : <PlaceOrderModal updateRefreshId={updateRefreshId} />}
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        <div className="flex flex-1 p-4 pt-0">
          <OrdersTable key={refreshId} updateRefreshId={updateRefreshId} />
        </div>
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(OrdersController);
