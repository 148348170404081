import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AcknowledgeOrderMutationVariables = Types.Exact<{
  params: Types.AcknowledgeOrderParams;
}>;


export type AcknowledgeOrderMutation = { __typename?: 'Mutation', acknowledgeOrder: { __typename?: 'AcknowledgeOrderResult', orderId: string } | { __typename?: 'OrderAlreadyAcknowledged' } | { __typename?: 'OrderIsDeleted' } | { __typename?: 'OrderNotFound' } | { __typename?: 'UserIsNotAdmin' } };


export const AcknowledgeOrderDocument = gql`
    mutation AcknowledgeOrder($params: AcknowledgeOrderParams!) {
  acknowledgeOrder(params: $params) {
    ... on AcknowledgeOrderResult {
      orderId
    }
  }
}
    `;
export type AcknowledgeOrderMutationFn = Apollo.MutationFunction<AcknowledgeOrderMutation, AcknowledgeOrderMutationVariables>;
export type AcknowledgeOrderMutationResult = Apollo.MutationResult<AcknowledgeOrderMutation>;
export type AcknowledgeOrderMutationOptions = Apollo.BaseMutationOptions<AcknowledgeOrderMutation, AcknowledgeOrderMutationVariables>;