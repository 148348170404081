import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
} from "@adobe/react-spectrum";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../../constants/authConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { useRedirection } from "../../hooks/useRedirection";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LogOutModal = (props: Props): React.ReactElement => {
  const { isOpen, onClose } = props;

  const { goToLoginPage } = useRedirection();

  const userAppConfigStore = useUserAppConfigStore();

  const onLogOutSuccess = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    Cookies.remove(REFRESH_TOKEN_KEY);
    onClose();
    goToLoginPage();
    userAppConfigStore.clearData();
  };

  const onLogOut = () => {
    onLogOutSuccess();
  };

  return (
    <DialogContainer onDismiss={() => onClose()}>
      {isOpen && (
        <Dialog size="S">
          <Heading>Log Out</Heading>
          <Divider />
          <Content>
            <Text>Are you sure you want to log out?</Text>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Cancel
            </Button>
            <Button variant="negative" onPress={onLogOut}>
              Log Out
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(LogOutModal);
