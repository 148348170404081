import { useLazyQuery } from "@apollo/client";

import { Order } from "../../graphql/graphqlTypes";
import { convertApolloError } from "../../utils/apiUtils";

import {
  GetOrdersInAccountQuery as Query,
  GetOrdersInAccountDocument as QueryDocument,
  GetOrdersInAccountQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: QueryVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => Promise<Order[]>;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetOrdersInAccount = (): ReturnType => {
  const [getOrdersInAccount, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = async (args: TriggerAPIArgs): Promise<Order[]> => {
    const response = await getOrdersInAccount({
      variables: {
        params: args.reqObj,
      },
      onError: args.onFailure,
      fetchPolicy: "network-only",
    });

    if (!response || !response.data) return [];

    return handleResponse({
      data: response.data,
      onSuccess: args.onSuccess,
      onFailure: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
