import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetInvoicesInAccountQueryVariables = Types.Exact<{
  params: Types.GetInvoicesInAccountParams;
}>;


export type GetInvoicesInAccountQuery = { __typename?: 'Query', getInvoicesInAccount: { __typename?: 'CompanyNotFound' } | { __typename?: 'GetInvoicesInAccountResult', totalCount: number, invoices?: Array<{ __typename?: 'Invoice', invoiceId: string, companyId: string, companyName: string, title: string, description?: string | null, isAcknowledged: boolean, creationDatetime: any, file: { __typename?: 'FileUploader', fileId: string, url: string, name: string, sizeInBytes: string } } | null> | null } | { __typename?: 'InvalidLimit' } | { __typename?: 'InvalidOffset' } };


export const GetInvoicesInAccountDocument = gql`
    query GetInvoicesInAccount($params: GetInvoicesInAccountParams!) {
  getInvoicesInAccount(params: $params) {
    ... on GetInvoicesInAccountResult {
      totalCount
      invoices {
        invoiceId
        companyId
        companyName
        title
        description
        isAcknowledged
        creationDatetime
        file {
          fileId
          url
          name
          sizeInBytes
        }
      }
    }
  }
}
    `;
export type GetInvoicesInAccountQueryResult = Apollo.QueryResult<GetInvoicesInAccountQuery, GetInvoicesInAccountQueryVariables>;