import { useState } from "react";
import { CalendarDate } from "@internationalized/date";

export const usePlaceOrderState = () => {
  const [entity, setEntity] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] =
    useState<CalendarDate | null>(null);
  const [quantity, setQuantity] = useState<number>(1);

  return {
    entity,
    description,
    expectedDeliveryDate,
    quantity,
    setEntity,
    setDescription,
    setExpectedDeliveryDate,
    setQuantity,
  };
};
