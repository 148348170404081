import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteOrderMutationVariables = Types.Exact<{
  params: Types.DeleteOrderParams;
}>;


export type DeleteOrderMutation = { __typename?: 'Mutation', deleteOrder: { __typename?: 'AdminCannotDeleteOrder' } | { __typename?: 'DeleteOrderResult', orderId: string } | { __typename?: 'OrderAccessDenied' } | { __typename?: 'OrderAlreadyAcknowledged' } | { __typename?: 'OrderNotFound' } };


export const DeleteOrderDocument = gql`
    mutation DeleteOrder($params: DeleteOrderParams!) {
  deleteOrder(params: $params) {
    ... on DeleteOrderResult {
      orderId
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;