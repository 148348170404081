import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type PlaceOrderMutationVariables = Types.Exact<{
  params: Types.PlaceOrderParams;
}>;


export type PlaceOrderMutation = { __typename?: 'Mutation', placeOrder: { __typename?: 'AdminCannotPlaceOrder' } | { __typename?: 'Order', orderId: string, companyId: string, entity: string, description?: string | null, expectedDeliveryDate?: any | null, quantity: number, isAcknowledged: boolean, isDeleted: boolean, acknowledgedBy?: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } | null } | { __typename?: 'OrderExpectedDeliveryDateCannotBeInPast' } | { __typename?: 'OrderQuantityMustGreaterThanZero' } };


export const PlaceOrderDocument = gql`
    mutation PlaceOrder($params: PlaceOrderParams!) {
  placeOrder(params: $params) {
    ... on Order {
      orderId
      companyId
      entity
      description
      expectedDeliveryDate
      quantity
      isAcknowledged
      isDeleted
      acknowledgedBy {
        userId
        name
        emailId
        profilePic
      }
    }
  }
}
    `;
export type PlaceOrderMutationFn = Apollo.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;