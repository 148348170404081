import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { PlaceOrderMutation as Mutation } from "./mutation.generatedTypes";

interface ArgsType {
  data: Mutation;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const handleResponse = (args: ArgsType) => {
    const response = args.data.placeOrder;

    switch (response.__typename) {
      case "Order": {
        args.onSuccess?.();
        break;
      }
      case "AdminCannotPlaceOrder":
      case "OrderExpectedDeliveryDateCannotBeInPast":
      case "OrderQuantityMustGreaterThanZero":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
