import { Content, Heading, IllustratedMessage } from "@adobe/react-spectrum";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import type { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { useGetInvoicesInAccount } from "../../apis/GetInvoicesInAccount/useGetInvoicesInAccount";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { Invoice } from "../../graphql/graphqlTypes";
import { formatBytes } from "../../utils/fileUtils";

import InvoiceExpandedModal from "../InvoiceExpandedModal/InvoiceExpandedModal";
import Loader from "../Loader/Loader";

interface Props {
  updateRefreshId: () => void;
}

const renderStatusBadge = (isAcknowledged: boolean): React.ReactElement => {
  return isAcknowledged ? (
    <div className="bg-green-100 px-2 py-1 rounded-2xl font-medium text-green-600 text-sm">
      Acknowledged
    </div>
  ) : (
    <div className="bg-blue-100 px-2 py-1 rounded-2xl font-medium text-blue-600 text-sm">
      Pending
    </div>
  );
};

const InvoicesTable = (props: Props): React.ReactElement => {
  const gridRef = useRef<AgGridReact<Invoice>>(null);

  const { isAdmin, companyId } = useUserAppConfigStore();

  const [activeInvoice, setActiveInvoice] = useState<Invoice | null>(null);
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);

  const onClose = () => setActiveInvoice(null);

  const getInvoicesAPI = useGetInvoicesInAccount();

  const getInvoices = (offset = 0) => {
    return getInvoicesAPI.triggerAPI({
      reqObj: {
        companyId,
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
      },
    });
  };

  const datasource: IDatasource = useMemo(
    () => ({
      getRows: async (params: IGetRowsParams) => {
        const { startRow, endRow, successCallback, failCallback } = params;

        try {
          const response = await getInvoices(startRow);

          const data: Invoice[] = response;

          const lastRow =
            data.length < endRow - startRow
              ? startRow + data.length
              : undefined;

          if (startRow === 0) setShowNoSearchResults(data.length === 0);

          successCallback(data, lastRow);
        } catch (error) {
          failCallback();
        }
      },
    }),
    []
  );

  const renderNoDataView = (): React.ReactElement => (
    <div className="bg-gray-50 w-full h-full">
      <IllustratedMessage>
        <NoSearchResults />
        <Heading>No Invoices Found</Heading>
        <Content>No Invoices are raised yet</Content>
      </IllustratedMessage>
    </div>
  );

  const columnDefs: ColDef[] = [
    {
      field: "companyName",
      headerName: "Company",
      width: 200,
      hide: !isAdmin,
    },
    {
      field: "title",
      headerName: "Invoice",
      width: 300,
    },
    {
      field: "file",
      headerName: "File Size",
      width: 200,
      valueFormatter: (value) =>
        value.value?.sizeInBytes ? formatBytes(value.value.sizeInBytes) : "-",
    },

    {
      field: "isAcknowledged",
      headerName: "Invoice Acknowledged",
      width: 300,
      cellRenderer: (value: any) => (
        <div className="flex items-center h-full">
          {renderStatusBadge(value.value)}
        </div>
      ),
      hide: true,
    },

    {
      field: "creationDatetime",
      headerName: "Invoice Date",
      width: 200,
      valueFormatter: (value) =>
        value.value ? new Date(value.value).toLocaleString("en-GB") : "-",
    },
  ];

  return (
    <div className="flex-1 mt-2 w-full h-full ag-theme-quartz">
      {showNoSearchResults ? (
        renderNoDataView()
      ) : (
        <AgGridReact<Invoice>
          ref={gridRef}
          columnDefs={columnDefs as any}
          defaultColDef={{
            sortable: false,
            onCellClicked({ data }) {
              if (data) setActiveInvoice(data);
            },
          }}
          rowModelType="infinite"
          cacheBlockSize={DEFAULT_API_LIMIT}
          maxConcurrentDatasourceRequests={1}
          datasource={datasource}
          loading={getInvoicesAPI.isLoading}
          loadingOverlayComponent={() => <Loader />}
        />
      )}

      {activeInvoice ? (
        <InvoiceExpandedModal
          invoice={activeInvoice}
          isOpen={!!activeInvoice}
          onClose={onClose}
          updateRefreshId={props.updateRefreshId}
        />
      ) : null}
    </div>
  );
};

export default InvoicesTable;
