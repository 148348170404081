import {
  Button,
  Dialog,
  DialogTrigger,
  Icon,
  Text,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { MdOutlineFileUpload } from "react-icons/md";

import UploadInvoiceController from "../../controllers/UploadInvoiceController/UploadInvoiceController";

interface Props {
  updateRefreshId: () => void;
}

const UploadInvoiceModal = (props: Props): React.ReactElement => {
  const renderCreateButton = (): React.ReactElement => (
    <Button variant="accent">
      <Icon width={16} height={16}>
        <MdOutlineFileUpload />
      </Icon>
      <Text>{"Upload Invoice"}</Text>
    </Button>
  );

  return (
    <DialogTrigger>
      {renderCreateButton()}
      {(close) => (
        <Dialog size="M">
          <UploadInvoiceController
            onClose={close}
            updateRefreshId={props.updateRefreshId}
          />
        </Dialog>
      )}
    </DialogTrigger>
  );
};

export default observer(UploadInvoiceModal);
