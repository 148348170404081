import { observer } from "mobx-react-lite";

import { useDeleteOrder } from "../../apis/DeleteOrder/useDeleteOrder";
import { useUpdateOrder } from "../../apis/UpdateOrder/useUpdateOrder";
import PlaceOrder from "../../components/PlaceOrder/PlaceOrder";
import { Order } from "../../graphql/graphqlTypes";
import { showSuccessToast } from "../../utils/toastUtils";

import { useUpdateOrderState } from "./useUpdateOrderState";

interface Props {
  order: Order;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateOrderController = (props: Props): React.ReactElement => {
  const { onClose } = props;

  const state = useUpdateOrderState(props.order);

  const updateOrderAPI = useUpdateOrder();
  const cancelOrderAPI = useDeleteOrder();

  const updateOrder = (): void => {
    const { description, expectedDeliveryDate, quantity } = state;

    const areDetailsValid = expectedDeliveryDate && quantity;

    if (!areDetailsValid) return;

    updateOrderAPI.triggerAPI({
      reqObj: {
        orderId: props.order.orderId,
        quantity: Number(quantity),
        expectedDeliveryDate: expectedDeliveryDate.toString(),
        description,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Order Updated");
        props.updateRefreshId();
      },
    });
  };

  const onCancelOrder = (): void => {
    cancelOrderAPI.triggerAPI({
      reqObj: { orderId: props.order.orderId },
      onSuccess: () => {
        onClose();
        showSuccessToast("Order Canceled");
        props.updateRefreshId();
      },
    });
  };

  return (
    <PlaceOrder
      entity={"entity"}
      expectedDeliveryDate={state.expectedDeliveryDate}
      quantity={state.quantity}
      description={state.description}
      setEntity={() => null}
      setExpectedDeliveryDate={state.setExpectedDeliveryDate}
      setQuantity={state.setQuantity}
      setDescription={state.setDescription}
      onClose={onClose}
      isEdit
      onPlaceOrder={updateOrder}
      onCancelOrder={onCancelOrder}
    />
  );
};

export default observer(UpdateOrderController);
