import {
  Button,
  ButtonGroup,
  Content,
  DatePicker,
  Divider,
  Heading,
  TextArea,
  TextField,
} from "@adobe/react-spectrum";
import { CalendarDate } from "@internationalized/date";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import CompaniesSearchController from "../../controllers/CompaniesSearchController/CompaniesSearchController";

interface Props {
  employeeId: string | null;
  employeeName: string | null;
  deliveryDate: CalendarDate | null | undefined;
  configuration: string | null;
  vendor: string | null;
  returnDate: CalendarDate | null | undefined;
  companyId: string | null;

  setEmployeeId: (employeeId: string | null) => void;
  setEmployeeName: (employeeName: string | null) => void;
  setConfiguration: (configuration: string | null) => void;
  setVendor: (configuration: string | null) => void;
  setDeliveryDate: (deliveryDate: CalendarDate | null) => void;
  setReturnDate: (returnDate: CalendarDate | null) => void;
  setCompanyId: (companyId: string) => void;

  onClose: () => void;
  onUpdateLaptop: () => void;
  isAlreadyRented: boolean;
  procuredDate?: CalendarDate;
}

const UpdateLaptop = (props: Props): React.ReactElement => {
  const {
    employeeId,
    employeeName,
    configuration,
    vendor,
    deliveryDate,
    returnDate,
    companyId,
    setEmployeeId,
    setEmployeeName,
    onClose,
    setConfiguration,
    setVendor,
    onUpdateLaptop,
    setDeliveryDate,
    setReturnDate,
    setCompanyId,
    isAlreadyRented,
    procuredDate,
  } = props;

  const isRentedStatus = !!companyId && !isAlreadyRented;

  const isBaseValid = !!configuration && !!vendor;
  const isValid = isRentedStatus
    ? isBaseValid && !!employeeName?.trim() && !!employeeId?.trim()
    : isBaseValid;

  const renderEmployeeId = () => (
    <TextField
      label="Employee Id"
      value={employeeId ?? ""}
      onChange={setEmployeeId}
      width={"100%"}
      isRequired
    />
  );

  const renderEmployeeName = () => (
    <TextField
      label="Employee Name"
      value={employeeName ?? ""}
      onChange={setEmployeeName}
      width={"100%"}
      isRequired
    />
  );

  const renderVendor = () => (
    <TextField
      label="Laptop Vendor"
      value={vendor ?? ""}
      onChange={setVendor}
      width={"100%"}
      isRequired
    />
  );

  const renderConfiguration = () => (
    <TextArea
      label="Configuration"
      value={configuration ?? ""}
      onChange={setConfiguration}
      width={"100%"}
      isRequired
    />
  );

  const onChangeDeliveryDate = (deliveryDate: CalendarDate) => {
    setDeliveryDate(deliveryDate);
    if (returnDate && deliveryDate.compare(returnDate) > 0) setReturnDate(null);
  };

  const renderDeliveryDate = (): React.ReactElement => (
    <DatePicker
      label="Delivery Date"
      value={deliveryDate}
      onChange={onChangeDeliveryDate}
      UNSAFE_className="flex-1 min-w-0"
      minValue={procuredDate}
      isRequired
    />
  );

  const renderReturnDate = (): React.ReactElement => (
    <DatePicker
      label="Return Date"
      value={returnDate}
      onChange={setReturnDate}
      UNSAFE_className="flex-1 min-w-0"
      minValue={deliveryDate ?? procuredDate ?? undefined}
    />
  );

  return (
    <>
      <Heading>Update Laptop Status</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderVendor()}
          {renderConfiguration()}

          <div className="flex gap-2">
            {isAlreadyRented ? null : (
              <CompaniesSearchController
                label="Rent Laptop to Company"
                companyId={companyId ?? ""}
                onChangeCompanyId={setCompanyId}
                containerClassName="flex-1 min-w-0"
              />
            )}
          </div>

          {isRentedStatus ? (
            <>
              <div className="flex gap-2">
                {renderEmployeeId()}
                {renderEmployeeName()}
              </div>
              <div className="flex gap-2">
                {renderDeliveryDate()}
                {renderReturnDate()}
              </div>
            </>
          ) : isAlreadyRented ? (
            renderReturnDate()
          ) : null}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button variant="accent" onPress={onUpdateLaptop} isDisabled={!isValid}>
          {"Update"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(UpdateLaptop);
