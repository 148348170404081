import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  FileTrigger,
  Heading,
} from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FaRegFilePdf } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

import { useGetPreSignedPutUrlForInvoice } from "../../apis/GetPreSignedPutUrlForInvoice/useGetPreSignedPutUrlForInvoice";
import { useUploadInvoice } from "../../apis/UploadInvoice/useUploadInvoice";
import { showFailureToast, showSuccessToast } from "../../utils/toastUtils";

import CompaniesSearchController from "../CompaniesSearchController/CompaniesSearchController";

interface Props {
  onClose: () => void;
  updateRefreshId: () => void;
}

interface FileDetails {
  file: File;
  url: string;
}

const UploadInvoiceController = (props: Props): React.ReactElement => {
  const { onClose, updateRefreshId } = props;

  const [companyId, setCompanyId] = useState<string | null>(null);
  const [file, setFile] = useState<FileDetails | null>(null);
  const [fileUploadingLoading, setFileUploadingLoading] = useState(false);

  const uploadInvoiceAPI = useUploadInvoice();

  const getPresignedPutUrlAPI = useGetPreSignedPutUrlForInvoice();

  const onUploadInvoice = (): void => {
    if (!file || !companyId) return;

    uploadInvoiceAPI.triggerAPI({
      reqObj: {
        companyId,
        file: {
          name: file.file.name,
          sizeInBytes: file.file.size.toString(),
          url: file.url,
        },
        title: file.file.name,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Invoice Uploaded Successfully");
        updateRefreshId();
      },
    });
  };

  const onUploadFile = (newFile: File): void => {
    if (!companyId) return;

    if (newFile.type !== "application/pdf") {
      showFailureToast("Only PDF files are allowed");
      return;
    }

    setFileUploadingLoading(true);

    const onSuccessFetchPresignedUrl = async (
      putUrl: string
    ): Promise<void> => {
      try {
        const response = await fetch(putUrl, {
          method: "PUT",
          headers: {
            "Content-Type": newFile.type,
            "x-amz-acl": "private",
          },
          body: newFile,
        });

        const uploadedUrl = response.url;
        setFile({ file: newFile, url: uploadedUrl });
      } catch (e) {
        setFile(null);
        showFailureToast("Something Went Wrong");
      }

      setFileUploadingLoading(false);
    };

    getPresignedPutUrlAPI.triggerAPI({
      reqObj: {
        companyId,
      },
      onSuccess(putUrl) {
        onSuccessFetchPresignedUrl(putUrl);
      },
    });
  };

  const isValid = !!companyId;

  const renderFile = (): React.ReactElement =>
    file ? (
      <div className="flex items-center gap-2 border-gray-300 bg-gray-50 p-2 border rounded-lg max-w-full">
        <FaRegFilePdf className="shrink-0" />
        <p className="min-w-0 font-normal text-sm truncate shrink">
          {file.file.name}
        </p>
        <button onPointerDown={() => setFile(null)} className="shrink-0">
          <MdOutlineClose />
        </button>
      </div>
    ) : (
      <FileTrigger
        onSelect={(e) => {
          if (!e) return;
          onUploadFile(e[0]);
        }}
        acceptedFileTypes={["application/pdf"]}
      >
        <Button variant="accent" isPending={fileUploadingLoading}>
          Select Invoice PDF
        </Button>
      </FileTrigger>
    );

  return (
    <>
      <Heading>{"Upload Invoice"}</Heading>
      <Divider />

      <Content>
        <div className={cn("flex flex-col items-start gap-4 w-full")}>
          <CompaniesSearchController
            label="Select Company to send Invoice to:"
            companyId={companyId ?? ""}
            onChangeCompanyId={setCompanyId}
            containerClassName="!w-full"
          />

          {companyId ? renderFile() : null}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={onUploadInvoice}
          isDisabled={!isValid}
          isPending={uploadInvoiceAPI.isLoading}
        >
          {"Upload"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(UploadInvoiceController);
