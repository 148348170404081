import {
  Button,
  ButtonGroup,
  Content,
  DatePicker,
  Divider,
  Heading,
  NumberField,
  SpectrumTextFieldProps,
  TextArea,
  TextField,
} from "@adobe/react-spectrum";
import { CalendarDate, today } from "@internationalized/date";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  entity: string | null;
  description: string | null;
  expectedDeliveryDate: CalendarDate | null;
  quantity: number | null;

  setEntity: (entity: string | null) => void;
  setDescription: (description: string | null) => void;
  setExpectedDeliveryDate: (expectedDeliveryDate: CalendarDate | null) => void;
  setQuantity: (quantity: number) => void;

  onClose: () => void;
  onPlaceOrder: () => void;
  onCancelOrder?: () => void;

  isEdit?: boolean;
}

const PlaceOrder = (props: Props): React.ReactElement => {
  const {
    entity,
    description,
    expectedDeliveryDate,
    quantity,
    setEntity,
    setDescription,
    setExpectedDeliveryDate,
    setQuantity,
    onClose,
    onPlaceOrder,
    isEdit,
  } = props;

  const commonProps: SpectrumTextFieldProps = {
    type: "text",
    width: "100%",
  };

  const areDetailsValid =
    entity?.trim() && !!expectedDeliveryDate && !!quantity;

  const renderEntity = () => (
    <TextField
      label="Laptop Configuration"
      value={entity ?? ""}
      onChange={setEntity}
      {...commonProps}
      isRequired
    />
  );

  const renderDescription = () => (
    <TextArea
      label="Remarks"
      value={description ?? ""}
      onChange={setDescription}
      {...commonProps}
    />
  );

  const renderDeliveryDate = (): React.ReactElement => (
    <DatePicker
      label="Expected Delivery Date"
      value={expectedDeliveryDate}
      onChange={setExpectedDeliveryDate}
      UNSAFE_className="flex-1 min-w-0"
      minValue={today("IST").add({ days: 1 })}
      isRequired
    />
  );

  const renderQuantity = () => (
    <NumberField
      label="Quantity"
      defaultValue={1}
      minValue={1}
      value={quantity ?? undefined}
      onChange={setQuantity}
      isRequired
    />
  );

  return (
    <>
      <Heading>{isEdit ? "Update Order Details" : "Place Order"}</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {isEdit ? null : renderEntity()}
          {renderDeliveryDate()}
          {renderQuantity()}
          {renderDescription()}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Close
        </Button>

        {isEdit ? (
          <Button variant="negative" onPress={props.onCancelOrder}>
            Cancel Order
          </Button>
        ) : null}

        <Button
          variant="accent"
          onPress={onPlaceOrder}
          isDisabled={!areDetailsValid}
        >
          {isEdit ? "Update" : "Place Order"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(PlaceOrder);
