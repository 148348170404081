import { Dialog, DialogContainer } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import UpdateOrderController from "../../controllers/UpdateOrderController/UpdateOrderController";
import { Order } from "../../graphql/graphqlTypes";

interface Props {
  order: Order;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateOrderModal = (props: Props): React.ReactElement => {
  const { order, isOpen, onClose, updateRefreshId } = props;

  return (
    <DialogContainer onDismiss={() => onClose()}>
      {isOpen && (
        <Dialog size="M">
          <UpdateOrderController
            order={order}
            onClose={onClose}
            updateRefreshId={updateRefreshId}
          />
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(UpdateOrderModal);
