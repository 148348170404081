import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetOrdersInAccountQueryVariables = Types.Exact<{
  params: Types.GetOrdersInAccountParams;
}>;


export type GetOrdersInAccountQuery = { __typename?: 'Query', getOrdersInAccount: { __typename?: 'CompanyNotFound' } | { __typename?: 'GetOrdersInAccountResult', totalCount: number, orders?: Array<{ __typename?: 'Order', orderId: string, companyId: string, companyName: string, entity: string, description?: string | null, expectedDeliveryDate?: any | null, quantity: number, isAcknowledged: boolean, isDeleted: boolean, acknowledgedBy?: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } | null } | null> | null } | { __typename?: 'InvalidLimit' } | { __typename?: 'InvalidOffset' } };


export const GetOrdersInAccountDocument = gql`
    query GetOrdersInAccount($params: GetOrdersInAccountParams!) {
  getOrdersInAccount(params: $params) {
    ... on GetOrdersInAccountResult {
      totalCount
      orders {
        orderId
        companyId
        companyName
        entity
        description
        expectedDeliveryDate
        quantity
        isAcknowledged
        isDeleted
        acknowledgedBy {
          userId
          name
          emailId
          profilePic
        }
      }
    }
  }
}
    `;
export type GetOrdersInAccountQueryResult = Apollo.QueryResult<GetOrdersInAccountQuery, GetOrdersInAccountQueryVariables>;