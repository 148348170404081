import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import { useAcknowledgeOrder } from "../../apis/AcknowledgeOrder/useAcknowledgeOrder";
import { Order } from "../../graphql/graphqlTypes";

interface Props {
  order: Order;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const AcknowledgeOrderModal = (props: Props): React.ReactElement => {
  const { order, isOpen, onClose, updateRefreshId } = props;

  const acknowledgeOrderAPI = useAcknowledgeOrder();

  const acknowledgeOrder = (): void => {
    acknowledgeOrderAPI.triggerAPI({
      reqObj: {
        orderId: order.orderId,
      },
      onSuccess: updateRefreshId,
    });
  };

  return (
    <DialogContainer onDismiss={() => onClose()}>
      {isOpen && (
        <Dialog size="M">
          <Heading>Update Order Delivery Status</Heading>

          <Divider />
          <Content>
            <div className="flex flex-col gap-1">
              <Text>Company: {order.companyName}</Text>
              <Text>Laptop Configuration: {order.entity}</Text>
              <Text>Quantity: {order.quantity}</Text>

              <Text UNSAFE_className="mt-4">Is the order delivered?</Text>
            </div>
          </Content>

          <ButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Close
            </Button>

            <Button
              variant="accent"
              onPress={acknowledgeOrder}
              isPending={acknowledgeOrderAPI.isLoading}
            >
              {"Yes, Delivered"}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(AcknowledgeOrderModal);
