import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UploadInvoiceMutationVariables = Types.Exact<{
  params: Types.UploadInvoiceParams;
}>;


export type UploadInvoiceMutation = { __typename?: 'Mutation', uploadInvoice: { __typename?: 'CompanyNotFound' } | { __typename?: 'Invoice', invoiceId: string } | { __typename?: 'MaxInvoiceFileSizeIsLimited' } | { __typename?: 'UserIsNotAdmin' } };


export const UploadInvoiceDocument = gql`
    mutation UploadInvoice($params: UploadInvoiceParams!) {
  uploadInvoice(params: $params) {
    ... on Invoice {
      invoiceId
    }
  }
}
    `;
export type UploadInvoiceMutationFn = Apollo.MutationFunction<UploadInvoiceMutation, UploadInvoiceMutationVariables>;
export type UploadInvoiceMutationResult = Apollo.MutationResult<UploadInvoiceMutation>;
export type UploadInvoiceMutationOptions = Apollo.BaseMutationOptions<UploadInvoiceMutation, UploadInvoiceMutationVariables>;