import { CalendarDate, parseDate } from "@internationalized/date";
import { useState } from "react";

import { Order } from "../../graphql/graphqlTypes";

export const useUpdateOrderState = (order: Order) => {
  const [description, setDescription] = useState<string | null>(
    order.description ?? null
  );
  const [expectedDeliveryDate, setExpectedDeliveryDate] =
    useState<CalendarDate | null>(
      order.expectedDeliveryDate ? parseDate(order.expectedDeliveryDate) : null
    );
  const [quantity, setQuantity] = useState<number | null>(order.quantity);

  return {
    description,
    expectedDeliveryDate,
    quantity,
    setDescription,
    setExpectedDeliveryDate,
    setQuantity,
  };
};
