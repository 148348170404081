import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useGetPreSignedGetUrlForInvoice } from "../../apis/GetPreSignedGetUrlForInvoice/useGetPreSignedGetUrlForInvoice";
import { Invoice } from "../../graphql/graphqlTypes";

import Loader from "../Loader/Loader";

interface Props {
  invoice: Invoice;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const InvoiceExpandedModal = (props: Props): React.ReactElement => {
  const { invoice, isOpen, onClose, updateRefreshId } = props;

  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const getPresignedUrlAPI = useGetPreSignedGetUrlForInvoice();

  const getUrl = () => {
    getPresignedUrlAPI.triggerAPI({
      reqObj: {
        invoiceId: invoice.invoiceId,
      },
      onSuccess: setFileUrl,
    });
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <DialogContainer onDismiss={() => onClose()} type="fullscreenTakeover">
      {isOpen && (
        <Dialog size="L">
          <Divider />
          <Content>
            <div className="h-full">
              {fileUrl ? (
                <object
                  data={fileUrl}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Your browser does not support PDFs.{" "}
                    <a href={fileUrl}>Download the PDF</a>
                  </p>
                </object>
              ) : (
                <Loader />
              )}
            </div>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Close
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(InvoiceExpandedModal);
