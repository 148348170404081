import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateOrderMutationVariables = Types.Exact<{
  params: Types.UpdateOrderParams;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'AdminCannotUpdateOrder' } | { __typename?: 'OrderAccessDenied' } | { __typename?: 'OrderAlreadyAcknowledged' } | { __typename?: 'OrderExpectedDeliveryDateCannotBeInPast' } | { __typename?: 'OrderNotFound' } | { __typename?: 'OrderQuantityMustGreaterThanZero' } | { __typename?: 'UpdateOrderResult', orderId: string, quantity: number, description?: string | null, expectedDeliveryDate: any } };


export const UpdateOrderDocument = gql`
    mutation UpdateOrder($params: UpdateOrderParams!) {
  updateOrder(params: $params) {
    ... on UpdateOrderResult {
      orderId
      quantity
      description
      expectedDeliveryDate
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;