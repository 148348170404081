import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import InvoicesTable from "../../components/InvoicesTable/InvoicesTable";
import UploadInvoiceModal from "../../components/UploadInvoiceModal/UploadInvoiceModal";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";

const InvoicesController = (): React.ReactElement => {
  const [refreshId, setRefreshId] = useState("");

  const updateRefreshId = (): void => setRefreshId(Date.now().toString());

  const { isAdmin } = useUserAppConfigStore();

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>
        Invoices
      </p>
      {isAdmin ? (
        <UploadInvoiceModal updateRefreshId={updateRefreshId} />
      ) : null}
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        <div className="flex flex-1 p-4 pt-0">
          <InvoicesTable key={refreshId} updateRefreshId={updateRefreshId} />
        </div>
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(InvoicesController);
