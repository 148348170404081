import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPreSignedPutUrlForInvoiceQueryVariables = Types.Exact<{
  params: Types.GetPreSignedPutUrlForInvoiceParams;
}>;


export type GetPreSignedPutUrlForInvoiceQuery = { __typename?: 'Query', getPreSignedPutUrlForInvoice: { __typename?: 'CompanyNotFound' } | { __typename?: 'GetPreSignedPutUrlForInvoiceResult', signedUrl: string } | { __typename?: 'UserIsNotAdmin' } };


export const GetPreSignedPutUrlForInvoiceDocument = gql`
    query GetPreSignedPutUrlForInvoice($params: GetPreSignedPutUrlForInvoiceParams!) {
  getPreSignedPutUrlForInvoice(params: $params) {
    ... on GetPreSignedPutUrlForInvoiceResult {
      signedUrl
    }
  }
}
    `;
export type GetPreSignedPutUrlForInvoiceQueryResult = Apollo.QueryResult<GetPreSignedPutUrlForInvoiceQuery, GetPreSignedPutUrlForInvoiceQueryVariables>;