import { observer } from "mobx-react-lite";

import { usePlaceOrder } from "../../apis/PlaceOrder/usePlaceOrder";
import PlaceOrder from "../../components/PlaceOrder/PlaceOrder";
import { showSuccessToast } from "../../utils/toastUtils";

import { usePlaceOrderState } from "./usePlaceOrderState";

interface Props {
  onClose: () => void;
  updateRefreshId: () => void;
}

const PlaceOrderController = (props: Props): React.ReactElement => {
  const { onClose } = props;

  const state = usePlaceOrderState();

  const placeOrderAPI = usePlaceOrder();

  const placeOrder = (): void => {
    const { entity, description, expectedDeliveryDate, quantity } = state;

    const areDetailsValid = entity && expectedDeliveryDate && quantity;

    if (!areDetailsValid) return;

    placeOrderAPI.triggerAPI({
      reqObj: {
        entity,
        quantity: Number(quantity),
        expectedDeliveryDate: expectedDeliveryDate.toString(),
        description,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Order Placed");
        props.updateRefreshId();
      },
    });
  };

  return (
    <PlaceOrder
      entity={state.entity}
      expectedDeliveryDate={state.expectedDeliveryDate}
      quantity={state.quantity}
      description={state.description}
      setEntity={state.setEntity}
      setExpectedDeliveryDate={state.setExpectedDeliveryDate}
      setQuantity={state.setQuantity}
      setDescription={state.setDescription}
      onClose={onClose}
      onPlaceOrder={placeOrder}
    />
  );
};

export default observer(PlaceOrderController);
