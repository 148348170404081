export const LOGIN_ROUTE = "/login";

export const DASHBOARD_ROUTE = "/dashboard";

export const INVENTORY_ROUTE = "/inventory";

export const COMPANIES_ROUTE = "/companies";

export const COMPANY_DETAILED_VIEW_ROUTE = `${COMPANIES_ROUTE}/:companyId`;

export const ORDERS_ROUTE = "/orders";

export const INVOICES_ROUTE = "/invoices";

export const TICKETS_ROUTE = "/tickets";

export const USERS_ROUTE = "/users";
